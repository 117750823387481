import React from "react"

const SectionJustifyCenter = props => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {props.children}
    </div>
  )
}

export default SectionJustifyCenter
