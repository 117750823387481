import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
import ProductMain from "../components/product/product-main"
import ProductDetails from "../components/product/product-details"
import ProductInstall from "../components/product/product-install"
import BlogOverview from "../components/blog/blog-overview"
import CompaniesOverview from "../components/companies/companies-overview"

import Container from "@material-ui/core/Container"
import ProductClientApps from "../components/product/product-client-apps"

import Particles from "../components/particle/particle"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props

    return (
      <Layout>
        <SEO />
        <Particles />
        <Hero />
        <ProductMain />
        <ProductDetails />
        <ProductClientApps />
        <Container maxWidth={"lg"}>
          <ProductInstall />
        </Container>
        <CompaniesOverview />
        <BlogOverview posts={data.allMarkdownRemark.edges} />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { sourceName: { eq: "blog" } } }
      limit: 5
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            tags
            title
            description
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 400, maxHeight: 360) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
