import React from "react"

const StepperShape = props => {
  return (
    <div>
      <svg
        className="Path_80503"
        viewBox="41.97 380.569 435.016 65.059"
        style={props.style}
      >
        <path
          fill={props.color}
          d="M445.33 380.57H41.97v65.059h401.614l2.17-2.17 31.232-31.232-31.657-31.658z"
        />
      </svg>
      <style jsx>
        {`
          .Path_80503 {
            z-index: -1;
          }
        `}
      </style>
    </div>
  )
}

export default StepperShape
