import React from "react"

const RoundCircleSmall = () => {
  return (
    <div>
      <svg className="Ellipse_2541">
        <ellipse cx="5" cy="5" fill="rgba(178,178,255,1)" rx="5" ry="5" />
      </svg>
      <style jsx>
        {`
          .Ellipse_2541 {
            position: absolute;
            overflow: hidden;
            right: 11%;
            top: 25%;
            z-index: -1;
          }
        `}
      </style>
    </div>
  )
}

export default RoundCircleSmall
