import React from "react"
import InstallationStepperItem from "./step-item"
import InstallationStepItemMobile from "./step-item-mobile"
import styled from "styled-components"

const installationSteps = [
  "Download & Extract",
  "Run script",
  "Proceed to the installation",
  "Follow the installation wizard",
]

const stepsDescription = [
  <div key={0}>
    <div>
      wget{" "}
      {
        "https://build.tigase.net/nightlies/dists/latest/tigase-server-dist-max.tar.gz"
      }
    </div>
    <div>tar -xf tigase-server-dist-max.tar.gz</div>
  </div>,
  "scripts/tigase.sh start etc/tigase.conf",
  "Open in web browser page: http://localhost:8080/setup/",
  "Enjoy the tutorial on web",
]

const InstallationStepper = () => {
  return (
    <div style={{ display: "inline-block" }}>
      <InstallationStepperItem
        color={"rgba(137,146,170,1)"}
        stepTitle={"Download & Extract"}
        stepNumber={"1"}
        left={0}
        stepDescription={
          <div>
            <div>
              wget{" "}
              {
                "https://build.tigase.net/nightlies/dists/latest/tigase-server-dist-max.tar.gz"
              }
            </div>
            <div>tar -xf tigase-server-dist-max.tar.gz</div>
          </div>
        }
      />
      <InstallationStepperItem
        color={"rgba(69,77,98,1)"}
        stepTitle={"Run script"}
        stepNumber={"2"}
        left={55}
        stepDescription={"scripts/tigase.sh start etc/tigase.conf"}
      />
      <InstallationStepperItem
        color={"rgba(30,71,120,1)"}
        stepTitle={"Proceed to the installation"}
        stepNumber={"3"}
        left={0}
        stepDescription={
          "Open in web browser page: http://localhost:8080/setup/"
        }
      />
      <InstallationStepperItem
        color={"rgba(141,189,217,1)"}
        stepTitle={"Follow the installation wizard."}
        stepNumber={"4"}
        left={55}
        stepDescription={"Enjoy the tutorial on web"}
      />
    </div>
  )
}
export default InstallationStepper

const InstallationTitleText = styled.div`
  font-family: "Fira Sans";
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 20px;
  text-align: left;
  color: #fff;
  margin-left: 20px;
  margin-top: 20px;
`

export const InstallationStepperMobile = () => {
  return (
    <div style={{ overflow: "scroll" }}>
      <InstallationTitleText>Installation Process</InstallationTitleText>
      {installationSteps.map((item, index) => {
        return (
          <InstallationStepItemMobile
            key={index}
            stepTitle={item}
            stepDescription={stepsDescription[index]}
          />
        )
      })}
    </div>
  )
}
