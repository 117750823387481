import React from "react"
import YouTube from "react-youtube"
import LazyLoad from "react-lazyload"

class YoutubePlayer extends React.Component {
  render() {
    const opts = {
      height: this.props.height,
      width: this.props.width,
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        fs: 0,
        modestbranding: 1,
      },
    }

    return (
      <LazyLoad height={this.props.height}>
        <YouTube videoId="MIlnQmrE-os" opts={opts} onReady={this._onReady} />
      </LazyLoad>
    )
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo()
  }
}

export default YoutubePlayer
