import React from "react"
import Section from "../../components/section/section"
import SectionTitleDescriptionCta from "../../components/section/section-title-description-cta"
import SectionJustifyCenter from "../../components/section/section-justify-center"
import YoutubePlayer from "../youtube/youtube-player"
import PopupDialog from "../popup/popup-dialog"
import InstallationStepper, {
  InstallationStepperMobile,
} from "../installationsteps/installation-stepper"
import Hidden from "@material-ui/core/Hidden"
import InstallationMobile from "../../../static/install/installation_mobile_background.png"
import ArrowRight from "../../../static/arrow/arrowright.svg"
import styled from "styled-components"

const InstallationContainerMobile = styled.div`
  width: 325px;
  height: 307px;
  display: flex;
  position: relative;
  margin: auto;
  align-items: "flex-end";
`

const ArrowRightContainer = styled.div`
  width: 35px;
  height: 35px;
  background: linear-gradient(#1e4778 0%, #09366b 100%);
  border-radius: 50%;
  position: absolute;
  bottom: -10px;
  right: 30px;
  display: flex;
  justify-content: center;
  alignitems: center;
`

const WatchTutorialText = styled.div`
  font-family: "Fira Sans";
  font-weight: bold;
  font-size: 8px;
  line-height: 20px;
  text-align: left;
  color: #fff;
  position: absolute;
  bottom: 0;
  right: 70px;
`

const ProductMain = () => {
  const [open, setOpen] = React.useState(false)

  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <Hidden only="xs">
        <Section
          background={<React.Fragment></React.Fragment>}
          right={
            <SectionTitleDescriptionCta
              title={"Install Tigase XMPP Server"}
              description={`4 easy steps to install Tigase XMPP Server. It won't take longer than 10 minutes.`}
              cta={<InstallationStepper />}
            />
          }
          left={
            <SectionJustifyCenter>
              <YoutubePlayer width={"500"} height={"350"} />
            </SectionJustifyCenter>
          }
        />
        <style jsx>
          {`
            img {
              width: 35px;
              height: 35px;
            }
          `}
        </style>
      </Hidden>
      <Hidden smUp>
        <Section
          center={
            <SectionTitleDescriptionCta
              title={" Install Our Software"}
              description={`4 easy steps to install the software. Don't miss the opportunity and check it. It' won't take longer than 10 minutes.`}
              cta={
                <div>
                  <InstallationContainerMobile>
                    <img
                      alt="tigase install"
                      src={InstallationMobile}
                      style={{
                        zIndex: -1,
                        height: 307,
                        width: 325,
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                    />
                    <ArrowRightContainer onClick={handleClickOpen}>
                      <img alt="arrow right" src={ArrowRight} />
                    </ArrowRightContainer>
                    <WatchTutorialText>Watch Tutorial</WatchTutorialText>
                    <InstallationStepperMobile />
                  </InstallationContainerMobile>
                  {setOpen && (
                    <PopupDialog open={open} handleClose={handleClose}>
                      <YoutubePlayer />
                    </PopupDialog>
                  )}
                </div>
              }
            />
          }
        />
      </Hidden>
    </React.Fragment>
  )
}

export default ProductMain
