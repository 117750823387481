import styled from "styled-components"
import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"

const Line = styled.div`
  width: 6px;
  height: 0px;
  background: transparent;
  border: 2px solid #8dbdd9;
  filter: drop-shadow(0px 0px 6px #8dbdd9);
`

const StepDescription = styled.div`
  display: block;
  margin: 10px;
  color: #d6dfe4;
  font-size: 12px;
`

const StepMobile = props => {
  const [stepVisible, setStepVisible] = useState(false)

  const setStepVisibleClick = () => {
    setStepVisible(!stepVisible)
  }

  return (
    <Grid
      container
      alignItems="center"
      onClick={setStepVisibleClick}
      style={{ margin: 7, marginLeft: 15 }}
    >
      <Line />
      <div style={{ marginLeft: 10, color: "#a1daeb" }}>{props.stepTitle}</div>
      {stepVisible && (
        <StepDescription>{props.stepDescription}</StepDescription>
      )}
    </Grid>
  )
}

export default StepMobile
