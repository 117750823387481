import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import HeroButtonContact from "../buttons/hero-button-contact"
import HeroButtonPricing from "../buttons/hero-button-pricing"
import ContactIcon from "../../../static/contact/contact.svg"

import { Link } from "gatsby"

import Background from "../../../static/background/hero-background.svg"

import Button from "@material-ui/core/Button"

const HeroMessageMain = styled.h1`
  font-weight: 900;
  letter-spacing: 0.05em;
  text-align: left;
`

const HeroMessageMainDot = styled.span`
  color: #18faeb;
`

const HeroContentContainer = styled.div`
  position: absolute;
  top: 30%;
  left: 15%;
`

const Tigase = styled.span`
  color: rgba(30, 71, 120, 1);
`

const HeroSubmessage = styled.p`
  font-family: "Fira Sans";
  font-weight: 500;
  font-size: 1em;
  letter-spacing: 0.1em;
  line-height: 1.3em;
  text-align: left;
  color: #3e4346;
  margin-top: 10px;
`

const HeroCTAButtonsContainer = styled.div`
  display: flex;
`

const TigaseMessageHero = () => {
  return (
    <React.Fragment>
      <HeroMessageMain>
        <Tigase>Tigase</Tigase> <br />
        Instant Communication, <br />
        Presence And Messaging
        <HeroMessageMainDot>.</HeroMessageMainDot>
      </HeroMessageMain>
      <HeroSubmessage>
        We like to make things scalable, in fact we are a little <br /> bit
        obsessed with optimizations.
      </HeroSubmessage>
    </React.Fragment>
  )
}

const TigaseMessageHeroMobile = () => {
  const HeroMessageTigaseMobile = styled.h1`
    font-family: "Fira Sans";
    font-weight: 300;
    font-size: 25px;
    line-height: 20px;
    text-align: left;
    color: #454d62;
    margin: 10%;
  `

  return (
    <React.Fragment>
      <Grid container style={{ alignItems: "center" }}>
        <Grid container>
          <Grid item xs={12}>
            <HeroMessageTigaseMobile>
              Tigase, Instant Communication, Presence And Messaging
            </HeroMessageTigaseMobile>
            <Link to="/contact">
              <Button
                variant="contained"
                color="primary"
                style={{ display: "flex", margin: "auto" }}
              >
                Contact
              </Button>
            </Link>
            <Link to="/pricing-xmpp">
              <Button
                color="primary"
                style={{ display: "flex", margin: "auto", fontSize: "0.7em" }}
              >
                Pricing
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const Hero = () => {
  const Container = styled.div`
    height: 100vh;
    display: flex;
    max-height: 1000px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${Background});
    @media (max-width: 700px) {
      display: none;
    }
  `

  const ContainerMobile = styled.div`
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${Background});
    min-height: 350px;
    display: flex;
    @media (min-width: 700px) {
      display: none;
    }
  `

  return (
    <React.Fragment>
      <Container>
        <HeroContentContainer>
          <TigaseMessageHero />
          <HeroCTAButtonsContainer>
            <Link to="/contact">
              <HeroButtonContact>
                <img src={ContactIcon} alt="Contact Icon" />
                <div style={{ marginLeft: 3 }}>Contact</div>
              </HeroButtonContact>
            </Link>
            <Link to="/pricing-xmpp">
              <HeroButtonPricing>Pricing</HeroButtonPricing>
            </Link>
          </HeroCTAButtonsContainer>
        </HeroContentContainer>
      </Container>
      <ContainerMobile>
        <TigaseMessageHeroMobile />
      </ContainerMobile>
    </React.Fragment>
  )
}

export default Hero
