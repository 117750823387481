import React from "react"
import Appstore from "../../../static/storeicons/appstore-download.svg"
import GooglePlay from "../../../static/storeicons/googleplay.svg"
import Jumbo, { JumboItemContainer } from "../jumbotron/jumbotron"
import styled from "styled-components"

const JumboItemLink = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s;
  justify-content: center;
  :hover {
    transform: scale(1.1);
  }
`

const JumboItemApps = props => {
  return (
    <JumboItemLink href={props.link} target="_blank">
      <div style={{ color: "white", margin: 15 }}>{props.title}</div>
      <img
        src={props.icon}
        style={{ width: 135, height: 40 }}
        alt={props.title}
      />
    </JumboItemLink>
  )
}

const CLIENT_APPS = [
  {
    title: "SiskIM for iOS",
    icon: Appstore,
    link: "https://apps.apple.com/us/app/siskin-im/id1153516838",
  },
  {
    title: "BeagleIM for MacOS",
    icon: Appstore,
    link: "https://apps.apple.com/us/app/beagleim-by-tigase-inc/id1445349494",
  },
  {
    title: "StorkIM for Android",
    icon: GooglePlay,
    link:
      "https://play.google.com/store/apps/details?id=org.tigase.messenger.phone.pro",
  },
]

const ProductClientApps = () => {
  return (
    <Jumbo
      title={"Client apps simple to use and rich in features"}
      section={
        <JumboItemContainer items={CLIENT_APPS} component={<JumboItemApps />} />
      }
    />
  )
}

export default ProductClientApps
