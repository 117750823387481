import styled from "styled-components"

const AppWrapper = styled.div`{
  .testimonialSlider {
    .image-gallery-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column-reverse;
      @media (max-width: 767px) {
        flex-direction: column-reverse;
        max-height: 800px;
      }
      .image-gallery-slide-wrapper {
        display: flex;
        flex-wrap: wrap;
        min-height: 250px;
        @media screen and (max-width: 1100px) and (min-width: 992px) {
          max-width: 56%;
          width: 56%;
        }
        @media (max-width: 991px) {
          max-width: 50%;
          width: 50%;
        }
        @media (max-width: 767px) {
          max-width: 100%;
          width: 100%;
        }
        > span {
          display: flex;
          @media (max-width: 480px) {
            justify-content: center;
          }
          .image-gallery-left-nav,
          .image-gallery-right-nav {
            display: none;
            position: relative;
            top: 0;
            transform: none;
            margin-top: 0;
          }
          .image-gallery-left-nav {
          }
          .image-gallery-right-nav {
            margin-left: 10px;
          }
        }
        .image-gallery-swipe {
          .image-gallery-slide {
            .image-gallery-description {
              background: transparent;
              bottom: 0px;
              color: #000;
              position: relative;
            }
          }
        }
      }
      .image-gallery-thumbnails-wrapper {
        height: 200px;
        width: 80%;
        @media (max-width: 767px) {
          max-width: 100%;
          width: 100%;
          height: auto;
          margin-top: 50px;
          overflow: hidden;
        }
        .image-gallery-thumbnails {
          overflow: initial;
          padding-left: 30px;
          @media (max-width: 991px) {
            padding-left: 0px;
          }
          @media (max-width: 767px) {
            overflow: hidden;
          }
        }
        .image-gallery-thumbnails-container {
          position: relative;
          @media screen and (max-width: 1100px) and (min-width: 992px) {
            margin-left: -20px;
            margin-top: 15px;
          }
          @media (max-width: 991px) {
            margin-left: -25px;
          }
          @media (max-width: 767px) {
            height: auto;
            margin-left: 0px;
          }
          img {
            border-radius: 0%;
            height: 100%;
            width: 100%;

            @media (max-width: 768px) {
              box-shadow: none;
            }
            @media (max-width: 991px) {
              width: 70px;
              height: 70px;
            }
            @media (max-width: 480px) {
              width: 70px;
              height: 70px;
            }
          }

          .image-gallery-thumbnail:nth-child(1) {
            position: absolute;
            top: 40%;
            left: 0;
            width: 100px;
            height: 100px;
            @media (max-width: 767px) {
              position: relative;
              top: 0;
              left: 0;
              width: calc(33.33% - 30px);
              height: auto;
              margin-right: 30px;
              margin-left: 10px;
            }
            img {
            }
          }
          .image-gallery-thumbnail:nth-child(2) {
            position: absolute;
            top: 40%;
            left: 25%;
            width: 100px;
            height: 100px;
            @media (max-width: 767px) {
              position: relative;
              top: 0;
              width: calc(33.33% - 30px);
              height: auto;
              margin-right: 30px;
              left: 0;
            }
          }
          .image-gallery-thumbnail:nth-child(3) {
            position: absolute;
            top: 40%;
            left: 50%;
            width: 100px;
            height: 100px;
            @media (max-width: 767px) {
              position: relative;
              top: 0;
              width: calc(33.33% - 30px);
              height: auto;
              margin-right: 30px;
              left: 0;
            }
          }
          .image-gallery-thumbnail:nth-child(4) {
            position: absolute;
            top: 40%;
            left: 75%;
            width: 100px;
            height: 100px;
            @media (max-width: 767px) {
              position: relative;
              top: 0;
              width: calc(33.33% - 30px);
              height: auto;
              margin-right: 30px;
              left: 0;
            }
          }
          .image-gallery-thumbnail:nth-child(5) {
            position: absolute;
            top: 40%;
            left: 100%;
            width: 100px;
            height: 100px;
            @media (max-width: 767px) {
              position: relative;
              top: 0;
              width: calc(33.33% - 30px);
              height: auto;
              margin-right: 30px;
              left: 0;
            }
          }
          .image-gallery-thumbnail {
            transition: all 0.35s ease;
            border: 0;
            border-radius: 0%;
            .image-gallery-thumbnail-inner {
              width: 100%;
              height: 100%;
            }
            .image-gallery-thumbnail-label {
              position: relative;
              font-size: 0.8em;
              color: #0f2137;
              top: 0;
              text-shadow: none;
              transform: none;
              white-space: normal;
              width: 100%;
            }
            &.active {
              border: 0;
              transform: scale(1.3);
              box-shadow: 0px 18px 68px 0px rgba(22, 30, 54, 0.25);
              @media (max-width: 700px) {
                transform: scale(1.1);
              }
              @media (max-width: 1100px) {
                box-shadow: none;
              }
              .image-gallery-thumbnail-inner {
                @keyframes pulse {
                  0% {
                    transform: translateX(-50%) translateY(-50%) translateZ(0)
                      scale(1);
                    opacity: 1;
                  }

                  100% {
                    transform: translateX(-50%) translateY(-50%) translateZ(0)
                      scale(1.5);
                    opacity: 0;
                  }
                }
                @media (max-width: 991px) {
                  @keyframes pulse {
                    0% {
                      transform: translateX(-50%) translateY(-50%) translateZ(0)
                        scale(1);
                      opacity: 0;
                    }

                    100% {
                      transform: translateX(-50%) translateY(-50%) translateZ(0)
                        scale(1.2);
                      opacity: 0;
                    }
                  }
                }
                &::before {
                  content: '';
                  position: absolute;
                  display: block;
                  width: 100%;
                  height: 100%;
                  box-shadow: 0 0 0 0.8px rgba(0, 0, 0, 0.1);
                  border-radius: 0%;
                  top: 50%;
                  left: 50%;
                  opacity: 0;
                  -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
                  animation: pulse 2.2s ease-out infinite;
                  backface-visibility: hidden;
                  pointer-events: none;
                }
                &::after {
                  content: '';
                  position: absolute;
                  display: block;
                  width: 100%;
                  height: 100%;
                  box-shadow: 0 0 0 0.8px rgba(0, 0, 0, 0.1);
                  border-radius: 0%;
                  top: 50%;
                  left: 50%;
                  opacity: 0;
                  -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
                  animation: pulse 2.2s ease-out infinite;
                  backface-visibility: hidden;
                  pointer-events: none;
                  animation-delay: 1s;
                }
              }
              img {
                position: relative;
                @media (max-width: 768px) {
                  margin: 10px 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .cardExtraImage {
    @media screen and (max-width: 1440px) and (min-width: 1100px) {
      margin-left: -270px;
      margin-top: 50px;
    }
  }
`

export default AppWrapper
