import React from "react"

const RoundCircleMedium = () => {
  return (
    <div>
      <svg className="Ellipse_2540">
        <ellipse
          cx="22.5"
          cy="22.5"
          fill="rgba(178,178,255,1)"
          rx="22.5"
          ry="22.5"
        />
      </svg>
      <style jsx>
        {`
          .Ellipse_2540 {
            position: absolute;
            overflow: hidden;
            width: 45px;
            height: 45px;
            right: 10%;
            top: 15%;
            z-index: -1;
          }
        `}
      </style>
    </div>
  )
}

export default RoundCircleMedium
