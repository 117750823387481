import React from "react"
import SectionRight from "../../components/section/section-right"
import styled from "styled-components"
import ReadMoreHexagon from "../buttons/read-more-hexagon"
import Hidden from "@material-ui/core/Hidden"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"

import Flexible from "../../../static/features/Flexible.svg"
import Optimized from "../../../static/features/Optimization.svg"
import Reliable from "../../../static/features/Reliable.svg"
import Scalable from "../../../static/features/Scalable.svg"
import Secure from "../../../static/features/Secure.svg"

const ProductDetailsContainer = styled.div`
  margin-top: 160px;
  margin-bottom: 160px;

  @media (max-width: 1200px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`

const SampleImage = props => {
  return (
    <img
      src={props.img}
      alt={props.alt}
      style={{ height: "auto", width: "100%", maxHeight: 500 }}
    />
  )
}

const PRODUCT_DETAILS = [
  {
    title: "Secure",
    description: `Based on recognized security standards and practices such as XMPP, TLS, SASL. Well tested with thousands of automated tests cases. ACL support allows to have fine grained access to the system. Hardened secure mode. It's Open Source.`,
    images: <SampleImage img={Secure} alt="secure" />,
  },
  {
    title: "Reliable",
    description: `Implemented with modern tools and development practices. Peer-reviewed code. Tested under large loads over extended period of time. Known to work without restart for over 3 years on production systems.`,
    images: <SampleImage img={Reliable} alt="reliable" />,
  },
  {
    title: "Scalable",
    description: `Supports clustering out of the box. Offers near-linear scalability for typical use-case scenarios. Tested under large load of over 30 million messages per second. Works on production systems with over 10 million users.`,
    images: <SampleImage img={Scalable} alt="scalable" />,
  },
  {
    title: "Flexible",
    description: `Well thought architecture with rich API allows to customize and extend the software in any way. REST API and scripting support allows to easily add new functions. Administrator commands can be added at run-time.`,
    images: <SampleImage img={Flexible} alt="flexible" />,
  },
  {
    title: "Optimized",
    description: `We are crazy about optimization. The main binary file size is less then 3MB. The Tigase XMPP Server can be run on embedded systems with as little as 50MB of RAM. On large systems it can handle huge number of users providing very low per-user cost.`,
    images: <SampleImage img={Optimized} alt="optimized" />,
  },
]

const ProductDetailsItem = props => {
  const SectionDetailsItemTitleDescription = (
    <Grid item xs={12} sm={6}>
      <SectionRight
        title={props.title}
        description={props.description}
        cta={props.link && <ReadMoreHexagon link={props.link} />}
      />
    </Grid>
  )

  return (
    <ProductDetailsContainer>
      <Container maxWidth={"lg"}>
        <Grid container justify="center">
          {props.direction === "right" && SectionDetailsItemTitleDescription}
          <Grid
            item
            xs={12}
            sm={3}
            style={{ width: "100%", position: "relative", padding: 20 }}
          >
            {props.images}
          </Grid>
          {props.direction === "left" && SectionDetailsItemTitleDescription}
        </Grid>
      </Container>
    </ProductDetailsContainer>
  )
}

const SectionRightLeftInvert = props => {
  return (
    <>
      <Hidden only="xs">
        <ProductDetailsItem
          title={props.title}
          description={props.description}
          images={props.images}
          direction={props.index % 2 === 0 ? "left" : "right"}
          link={props.link}
        />
      </Hidden>
      <Hidden smUp>
        <ProductDetailsItem
          title={props.title}
          description={props.description}
          images={props.images}
          direction={"right"}
          link={props.link}
        />
      </Hidden>
    </>
  )
}

const ProductDetails = () => {
  return (
    <React.Fragment>
      {PRODUCT_DETAILS.map((item, index) => {
        return (
          <SectionRightLeftInvert
            key={index}
            title={item.title}
            description={item.description}
            images={item.images}
            item={item}
            index={index}
            link={item.link}
          />
        )
      })}
    </React.Fragment>
  )
}

export default ProductDetails
