import React from "react"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"

import AppWrapper from "./product-main-desktop-style"
import VideoIcon from "../../../static/usecases/VOIP.svg"
import ChatIcon from "../../../static/usecases/Chat.svg"
import TeamCollaborationicon from "../../../static/usecases/TeamCollaboraiton.svg"
import PushNotifications from "../../../static/usecases/PushNotifications.svg"
import FileSharingIcon from "../../../static/usecases/FileSharing.svg"

import SectionRight from "../../components/section/section-right"
import styled from "styled-components"

const MainProductTitle = styled.h2`
  color: white;
  text-align: center;
  width: 100%;
  color: #454d62;

  @media (min-width: 700px) {
    margin: 20px;
    padding: 3%;
  }
`

const products_desktop = [
  <SectionRight
    key={0}
    title={"VoIP"}
    description={`Voice and video calls, no glitching, secure and reliable, great user experience.`}
  />,
  <SectionRight
    key={1}
    title={"Simple Chat"}
    description={`Send a message and chat in real-time, faster than e-mail, better than SMS/Text messages.`}
  />,
  <SectionRight
    key={2}
    title={"Team Collaboration"}
    description={`Team chat rooms to talk and share files, organize work and communicate in real-time.`}
  />,
  <SectionRight
    key={3}
    title={"Push Notifications"}
    description={`Push millions of notifications simultaneously to all subscribed users or devices.`}
  />,
  <SectionRight
    key={4}
    title={"File Sharing"}
    description={`Easily share documents, photos or any file with your friend or within a team.`}
  />,
]

const images = [
  {
    thumbnail: `${VideoIcon}`,
    thumbnailAlt: "thumb one",
    description: products_desktop[0],
    thumbnailLabel: "VoIP",
  },
  {
    thumbnail: `${ChatIcon}`,
    thumbnailAlt: "thumb two",
    description: products_desktop[1],
    thumbnailLabel: "Simple Chat",
  },
  {
    thumbnail: `${TeamCollaborationicon}`,
    thumbnailAlt: "thumb three",
    description: products_desktop[2],
    thumbnailLabel: "Team Collaboration",
  },
  {
    thumbnail: `${PushNotifications}`,
    thumbnailAlt: "thumb four",
    description: products_desktop[3],
    thumbnailLabel: "Push Notifications",
  },
  {
    thumbnail: `${FileSharingIcon}`,
    thumbnailAlt: "thumb five",
    description: products_desktop[4],
    thumbnailLabel: "File Sharing",
  },
]

const ProductMainDesktop = () => {
  return (
    <div>
      <AppWrapper>
        <div className="testimonialSlider" id="testimonialSection">
          <div>
            <div
              className="testimonialDesWrapper"
              style={{ overflow: "hidden" }}
            >
              <MainProductTitle>
                Proven technology for many use cases.
              </MainProductTitle>
              <ImageGallery
                items={images}
                originalClass="Testimonial-img"
                showPlayButton={false}
                showFullscreenButton={false}
                showNav={false}
              />
            </div>
          </div>
        </div>
      </AppWrapper>
    </div>
  )
}

export default ProductMainDesktop
