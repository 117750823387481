import React, { useState } from "react"
import StepperShape from "../shapes/stepper"
import styled from "styled-components"

const Step1Title = styled.div`
  font-family: "Fira Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 31px;
  color: #fff;
  height: 65px;
  width: 430px;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 70px;
`

const StepContainer = styled.div`
  position: relative;
  cursor: pointer;
`

const StepBox = styled.div`
  width: 78.3px;
  height: 78.3px;
  border-radius: 22px;
  background: #fff;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.15);
  z-index: -1;
`

const StepBoxContainer = styled.div`
  position: absolute;
  top: -10px;
  left: -30px;
  transform: rotate(45deg);
  position: absolute;
`

const StepNumber = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  font-family: "Fira Sans";
  font-weight: 900;
  font-size: 35px;
  line-height: 42px;
  text-align: center;
`

const StepDescription = styled.div`
  position: relative;
`

const StepItem = props => {
  const [stepVisible, setStepVisible] = useState(false)

  const setStepVisibleClick = () => {
    setStepVisible(!stepVisible)
  }

  return (
    <React.Fragment>
      <StepContainer style={{ left: props.left }} onClick={setStepVisibleClick}>
        <StepBoxContainer>
          <StepBox />
        </StepBoxContainer>
        <StepNumber style={{ color: props.color }}>
          {props.stepNumber}
        </StepNumber>
        <StepperShape
          style={{ width: 400, position: "absolute", top: 0, left: 0 }}
          color={props.color}
        />
        <Step1Title>{props.stepTitle}</Step1Title>
      </StepContainer>
      {stepVisible && (
        <StepDescription
          style={{
            left: props.left + 40,
            margin: props.stepDescription ? 20 : 0,
          }}
        >
          {props.stepDescription}
        </StepDescription>
      )}
    </React.Fragment>
  )
}

export default StepItem
