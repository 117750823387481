import React from "react"

import RoundCircleMedium from "../shapes/round-circle-medium"
import RoundCircleSmall from "../shapes/round-circle-small"

import ProductMainDesktop from "./product-main-desktop"

import Jumbo, { JumboItemContainer } from "../jumbotron/jumbotron"
import Container from "@material-ui/core/Container"

import Client from "../../../static/productmain/application.svg"
import Server from "../../../static/productmain/server.svg"
import Library from "../../../static/productmain/library.svg"

import styled from "styled-components"
import { Link } from "gatsby"

const XMPPItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s;
  justify-content: center;
  :hover {
    transform: scale(1.2);
  }
`

const JumboItemProducts = props => {
  return (
    <Link to={props.link}>
      <XMPPItem>
        <img
          src={props.icon}
          style={{ width: 40, margin: 10 }}
          alt={props.title}
        />
        <div style={{ color: "white", margin: 15 }}>{props.title}</div>
      </XMPPItem>
    </Link>
  )
}

const MAIN_PRODUCTS = [
  {
    title: "XMPP Server",
    icon: Server,
    link: "/xmpp-server",
  },
  {
    title: "XMPP Clients",
    icon: Client,
    link: "/xmpp-clients",
  },
  {
    title: "XMPP Libraries",
    icon: Library,
    link: "/xmpp-libraries",
  },
]

const ProductMain = () => {
  return (
    <React.Fragment>
      <Jumbo
        title={
          <div>
            Instant Communication, Presence and Messaging software <br />
            based on the <a href="https://xmpp.org">XMPP</a> standard
          </div>
        }
        section={
          <JumboItemContainer
            items={MAIN_PRODUCTS}
            component={<JumboItemProducts />}
          />
        }
      />
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <RoundCircleMedium />
        <RoundCircleSmall />
      </div>
      <Container maxWidth={"lg"}>
        <ProductMainDesktop />
      </Container>
    </React.Fragment>
  )
}

export default ProductMain
